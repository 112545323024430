import {
  AdItem,
  AdTypes,
  AdvertiserType,
  CategoryID,
  FilterURI,
  FilterWithValues,
  HTTPStatusCode,
  ItemURN,
  NetError,
} from '@sbt-web/network/types';
import type { PublicUser } from '@sbt-web/auth';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { BuyerInfo } from '@sbt-web/networking';
import { Button, Headline4, Icon } from '@sbt-web/ui';
import { adTypesToSlugMap } from '@tools/search/values';
import classNames from 'classnames';
import React, {
  type FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMessageValidation } from './hooks/useMessageValidation';
import {
  clickRecommendationSearchEvent,
  sendFormButtonClick,
  sendMessageSuccessEvent,
  sendMessageSuccessGTMEvent,
  viewRecommendationSearchEvent,
} from '../../tracking';
import type { ErrorCode, SearchData } from '../../types';
import { ERRORS_CODE, getError } from './utils/errors';
import { HADES_PATH } from '@shared/constants';
import {
  AttachmentField,
  ConfirmMessage,
  ErrorDialog,
  MessageField,
  PhoneForwarder,
} from './components';
import { LiraLazySlotReplyBox } from '@client/components/Adv/Lira/LiraLazySlotReplyBox';
import {
  getBuyerInfo,
  searchRecommended,
  sendAdReply,
} from '@sbt-web/network/client';

import styles from './form.module.scss';
import { getCategoryFriendlyNameByID } from '@sbt-web/utils';

interface FormProps {
  isMobile: boolean;
  onCloseContactForm: () => void;
  isPro: boolean;
  category: string;
  enableAttachments: boolean;
  userId: string;
  advertiserName: string;
  subject: string;
  adUrn: string;
  requestLogin: () => Promise<PublicUser>;
  item: AdItem;
  onSendReplyCallback?: () => Promise<void>;
  buyerName?: string;
  search?: SearchData;
}

interface SuccessEvent {
  isPhoneTrack: boolean;
  phone: string;
  forwardPhone?: boolean;
  search?: SearchData;
}

export interface TrackingSendFormData {
  cv: boolean | null;
  phone: boolean | null;
  editText: boolean;
}

const JOB_OFFERS_CATEGORY_ID = '26';

const dispatchSuccessEvent = ({
  isPhoneTrack,
  forwardPhone,
  phone,
  search,
}: SuccessEvent): void => {
  // this event cannot be removed. It's used on GTM and Prebid scripts.
  window.dispatchEvent(
    new CustomEvent('subito:AdReply:Success', {
      detail: {
        forwardPhone,
        isPhoneTrack,
        phone,
        search,
        disabled: true,
      },
    })
  );
};

export const Form = ({
  isMobile,
  onCloseContactForm,
  isPro,
  category,
  enableAttachments,
  userId,
  advertiserName,
  subject,
  adUrn,
  onSendReplyCallback,
  requestLogin,
  buyerName,
  search,
  item,
}: FormProps) => {
  const shouldRenderPhoneForwarder = useMemo(
    () => isPro && category !== JOB_OFFERS_CATEGORY_ID,
    [isPro, category]
  );

  const [buyerPhone, setBuyerPhone] = useState<string | undefined>(undefined);
  const [curriculum, setCurriculum] = useState<Blob | undefined>(undefined);
  const initialMessage = `Ciao ${advertiserName}, ti contatto per l'annuncio ${subject}, `;
  const [message, setMessage] = useState<string>(initialMessage);
  const [replyDone, setReplyDone] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const { errorMessage, isValid } = useMessageValidation();

  const [errors, setErrors] = useState<string[]>([]);
  const submitRef = useRef<boolean>(false);
  const { optimizely } = useContext(OptimizelySubitoContext);
  const [recommendationSearch, setRecommendationSearch] = useState<{
    count: number;
    url: string;
  }>();

  const checkRelated = useCallback(async (urn: ItemURN) => {
    try {
      const res = await searchRecommended(
        process.env.NEXT_PUBLIC_HADES_BASE_URL,
        urn
      );
      const { count, filters } = res.body;
      if (!count) return;

      const url = buildRecommendedUrlFromFilter(filters);
      setRecommendationSearch({ count, url });
    } catch {
      return;
    }
  }, []);

  useEffect(() => {
    if (
      item.category.id === CategoryID.Auto &&
      item.advertiser.type === AdvertiserType.Private
    ) {
      checkRelated(item.urn);
    }
  }, [item, checkRelated]);

  useEffect(() => {
    let didCancel = false;
    (async () => {
      try {
        const response = await getBuyerInfo(HADES_PATH, userId);
        if (!didCancel && response?.body?.buyerInfo) {
          setBuyerPhone(response.body.buyerInfo.phone);
        }
      } catch (e) {
        console.error(e);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [userId, buyerName]);

  const handleSetPhoneNumber = (phoneNumber: string) =>
    setBuyerPhone(phoneNumber);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!submitRef.current && isValid(message)) {
      submitRef.current = true;
      sendAdreply(userId);
    }
  };

  const sendDoneTracking = (buyerInfo: BuyerInfo) => {
    const isEditMessage = initialMessage.trim() !== message.trim();
    const trackingData: TrackingSendFormData = {
      cv: enableAttachments ? !!curriculum : null,
      phone: shouldRenderPhoneForwarder ? !!buyerInfo.phone : null,
      editText: isEditMessage,
    };

    sendFormButtonClick(trackingData);
  };

  const buildBuyerInfo = (
    buyerId: string,
    hermesBuyerInfo?: BuyerInfo
  ): BuyerInfo => {
    const name =
      buyerId === userId
        ? (buyerName ?? hermesBuyerInfo?.name ?? '')
        : (hermesBuyerInfo?.name ?? '');
    return {
      ...hermesBuyerInfo,
      userId: buyerId,
      email: hermesBuyerInfo?.email ?? '',
      name,
      forwardPhone:
        buyerPhone !== undefined ? !!buyerPhone : hermesBuyerInfo?.forwardPhone,
      phone: buyerPhone ?? hermesBuyerInfo?.phone,
    };
  };

  const trackSendAdReply = () => {
    optimizely?.onReady().then(() => {
      optimizely.track('Send_Message');
      if (item.category.id === CategoryID.Auto) {
        const adType = isPro ? 'pro' : 'pri';
        optimizely.track(`lead_auto_${adType}`);
      }
    });
  };

  const sendAdreply = async (buyerId: string) => {
    let buyerInfo, getBuyerInfoResponse;
    try {
      getBuyerInfoResponse = await getBuyerInfo(HADES_PATH, buyerId);
    } catch (error) {
      if (
        error instanceof NetError &&
        error?.cause.status !== HTTPStatusCode.NotFound
      ) {
        setShowErrorDialog(true);
      }
    }
    try {
      //check if user is logged in with the same account
      buyerInfo = buildBuyerInfo(
        buyerId,
        getBuyerInfoResponse?.body?.buyerInfo
      );

      const responseSendAdReply = await sendAdReply(
        HADES_PATH,
        adUrn,
        message,
        {
          ...buyerInfo,
        },
        curriculum
      );

      submitRef.current = false;

      if (responseSendAdReply?.status === HTTPStatusCode.OK) {
        handleSuccessRequest(buyerInfo);
      }
    } catch (error) {
      if (error instanceof NetError) {
        switch (error.cause.status) {
          case HTTPStatusCode.BadRequest:
            handleBadRequest(error.cause);
            break;

          case HTTPStatusCode.OK:
            if (buyerInfo) handleSuccessRequest(buyerInfo);
            break;

          case HTTPStatusCode.Unauthorized: {
            handleUnauthorized();
            break;
          }

          case HTTPStatusCode.PayloadTooLarge: {
            handlePayloadTooLarge();
            break;
          }

          default:
            setShowErrorDialog(true);
            break;
        }
      }
    }
  };

  const handleBadRequest = (payload: unknown) => {
    if (payload && typeof payload === 'object' && 'errors' in payload) {
      const sendAdReplyErrors = (payload.errors as ErrorCode[]).map(
        (error) => error.error_code
      );
      const isErrorWithLabel = getError(ERRORS_CODE, sendAdReplyErrors);

      if (isErrorWithLabel) {
        setErrors(sendAdReplyErrors);
      } else {
        setShowErrorDialog(true);
      }
    }
  };

  const handlePayloadTooLarge = () => {
    setErrors(['REPLY:too-large-request']);
  };

  const handleSuccessRequest = (buyerInfo: BuyerInfo) => {
    setReplyDone(true);

    // Optimizely Tracking
    trackSendAdReply();
    if (item.category.id === CategoryID.OfferteLavoro) {
      // Linked to the experiment "job-photo-hidden"
      optimizely?.track('sent-job-message');
    }

    // Event dispatch
    dispatchSuccessEvent({
      forwardPhone: buyerInfo.forwardPhone,
      isPhoneTrack: shouldRenderPhoneForwarder,
      phone: buyerInfo.phone ?? '',
      search,
    });

    // Pulse
    sendDoneTracking(buyerInfo);

    // GTM
    sendMessageSuccessGTMEvent();

    // Pulse
    sendMessageSuccessEvent({
      item,
      forwardPhone: buyerInfo.forwardPhone,
      isPhoneTrack: shouldRenderPhoneForwarder,
      phone: buyerInfo.phone ?? '',
      search,
    });

    onSendReplyCallback?.();
  };

  const handleUnauthorized = async () => {
    const { id } = await requestLogin();

    if (id) {
      sendAdreply(id);
    }
  };

  const handleConfirmMessageCtaClick = () => {
    if (!recommendationSearch) {
      onCloseContactForm();
    } else {
      clickRecommendationSearchEvent(recommendationSearch.count);
      window.location.assign(recommendationSearch.url);
    }
  };

  useEffect(() => {
    if (recommendationSearch && replyDone) {
      viewRecommendationSearchEvent(recommendationSearch.count);
    }
  }, [replyDone, recommendationSearch]);

  return (
    <>
      <div className={styles.formWrapper}>
        {!isMobile && (
          <Button
            aria-label="Chiudi"
            onClick={onCloseContactForm}
            design="text"
            icon={<Icon name="Close" />}
            classes={[styles.closeButton]}
          />
        )}
        <div
          id="adreply_form_container"
          className={classNames(styles.container, {
            [styles.hide]: replyDone,
          })}
        >
          {!isMobile && <Headline4>Contatta l&apos;utente</Headline4>}
          <form
            method="post"
            name="adreply_form"
            onSubmit={handleSubmit}
            className={styles.form}
          >
            <MessageField
              message={message}
              onChangeMessage={(newMessage: string) => {
                isValid(newMessage);
                setMessage(newMessage);
              }}
              errorMessage={errorMessage}
            />

            {shouldRenderPhoneForwarder && (
              <PhoneForwarder
                phone={buyerPhone}
                onSetPhoneNumber={handleSetPhoneNumber}
                isMobile={isMobile}
              />
            )}
            {enableAttachments && (
              <AttachmentField
                name="curriculum"
                placeholder="Carica file"
                onChange={(file) => setCurriculum(file)}
                errors={errors}
              />
            )}
            <Button type="submit" classes={[styles.submitButton]}>
              Invia
            </Button>
          </form>
        </div>

        {replyDone && (
          <ConfirmMessage
            advertiserName={advertiserName}
            isVisible={replyDone}
            categoryId={item.category.id}
            onClick={handleConfirmMessageCtaClick}
            recommendedSearch={!!recommendationSearch}
          />
        )}
        {replyDone ? <LiraLazySlotReplyBox /> : null}
      </div>

      <ErrorDialog
        isOpened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        onRetry={() => {
          setShowErrorDialog(false);
          sendAdreply(userId);
        }}
      />
    </>
  );
};

export function buildRecommendedUrlFromFilter(filters: FilterWithValues[]) {
  const filterMap = filters.reduce(
    (map, filter) => ({ ...map, [filter.uri]: filter }),
    {} as Record<FilterURI, FilterWithValues>
  );

  const place = filterMap['/geo/region']?.values[0].metadata?.friendly_name;
  const adTypeKey = filterMap['/type']?.values[0].key as AdTypes;
  const adType = adTypesToSlugMap[adTypeKey];
  const category = getCategoryFriendlyNameByID(
    filterMap['/category']?.values[0].key as CategoryID
  );

  const recommendationUrl = new URL(
    `${process.env.NEXT_PUBLIC_ENV_BASE_URL}/annunci-${place}/${adType}/${category}/`
  );

  const advType = filterMap['/advertiser_type']?.values[0].key;
  const carBrand = filterMap['/car/brand']?.values[0].key;
  const carModel = filterMap['/car/model']?.values[0].key;
  const priceMin = filterMap['/price/min']?.values[0].key;
  const priceMax = filterMap['/price/max']?.values[0].key;

  recommendationUrl.searchParams.set('advt', advType);
  recommendationUrl.searchParams.set('cb', carBrand);
  recommendationUrl.searchParams.set('cm', carModel);
  recommendationUrl.searchParams.set('ps', priceMin);
  recommendationUrl.searchParams.set('pe', priceMax);

  return recommendationUrl.toString();
}
